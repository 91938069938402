<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import moment from 'moment';

export default {
  locales: {
    pt: {
      'Signals': 'Sinais',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Signals': 'Sinais',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      calendar: "",

      date: moment().format('YYYY-MM-DD'),

      loading: true,
      errored: false,
      empty: false,
      signals: null
    };
  },
  methods: {
    showDate (date) {
			this.date = date
		},
    getSignals: function () {
      api
        .get('signals?date='+this.date)
        .then(response => {
          this.loading = false
          if (response.data.status=='success') {
            this.signals = response.data.list
            this.empty = false
          } else {
            this.signals = []
            this.empty = true
          }
        })
        .catch(error => {
          this.errored = error
        })
    }
  },
  mounted() {
    this.getSignals()
  },
  watch: {
    date: function (v) {
      if (v.length == 10) {
        this.getSignals()
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Signals') }}</h4>
          <div class="page-title-right">
            <input type="date" v-model="date">
          </div>
        </div>
      </div>
    </div>
    <div v-if="errored"  class="card card-body">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
    <div v-else-if="empty" class="card card-body">{{ t('No records found.') }}</div>
    <div v-else>
      <vcl-list v-if="loading" class="col-lg-6"></vcl-list>
      <div class="row">
        <div class="col-md-4" v-for="(signal, index) in signals" :key="index">
          <div class="card border-success" style="border-left-width: 10px;">
            <div class="card-body p-0 px-3">
              <div class="d-flex">
                <div class="flex-fill py-3">
                  <div class="d-flex justify-content-between">
                    <div class="align-self-center text-uppercase">
                      <strong>{{ signal.currency }}</strong><br>
                      {{ signal.date }}
                    </div>
                  </div>
                  <div class="d-flex mt-1 font-size-13">
                    <div v-if="signal.type === 'PUT'" class="rounded mr-1 py-1 px-2 text-uppercase bg-danger text-white">{{ signal.type }}</div>
                    <div v-else class="rounded mr-1 py-1 px-2 text-uppercase bg-success text-white">{{ signal.type }}</div>
                    <div class="border rounded mr-1 py-1 px-2 text-uppercase bg-soft-dark">{{ signal.graphic }}</div>
                    <div v-if="signal.result === 'WIN'" class="rounded mr-1 py-1 px-2 bg-success text-white">{{ signal.result }}</div>
                    <div v-else-if="signal.result === 'LOSS'" class="rounded mr-1 py-1 px-2 bg-danger text-white">{{ signal.result }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.border-left-success {
  border-left: 4px solid #006837;
}
.border-left-danger {
  border-left: 4px solid #c1272d;
}
</style>